import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { FormattedMessage } from '../../../../../util/reactIntl';

import { ExternalLink, NamedLink } from '../../../../../components';

import css from './PriorityLinks.module.css';
import { Tooltip } from 'react-tooltip';
import { userLocation } from '../../../../../util/maps';
import { createResourceLocatorString } from '../../../../../util/routes';
import { useRouteConfiguration } from '../../../../../context/routeConfigurationContext';
import { encodeLatLng } from '../../../../../util/urlHelpers';

/**
 * Create component that shows only a single "Post a new listing" link.
 *
 * @param {*} props contains customLinksMenuClass
 * @returns div with only one link inside.
 */
export const CreateListingMenuLink = props => {
  return (
    <div className={props.customLinksMenuClass}>
      <NamedLink name="NewListingPage" className={classNames(css.priorityLink, css.highlight)}>
        <span className={css.priorityLinkLabel}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    </div>
  );
};

/**
 * Link component that can be used on TopbarDesktop.
 *
 * @param {*} props containing linkConfig including resolved 'route' params for NamedLink.
 * @returns NamedLink or ExternalLink component based on config.
 */
const PriorityLink = ({ linkConfig, askUserForLocation }) => {
  const { text, type, href, route, highlight } = linkConfig;
  const classes = classNames(css.priorityLink, { [css.highlight]: highlight });
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    return (
      <>
        <Tooltip id="tooltip" variant="info" place="bottom-end">
          <div className={css.tooltipContent}>
            To show listings in your area, Werke will request your location once. Once you create an
            account, your location will default to the zip code listed in your account profile
          </div>
        </Tooltip>
        {text === 'Find Work' && askUserForLocation ? (
          <a data-tooltip-id="tooltip" className={css.priorityLink}>
            <button
              name={name}
              onClick={async () => {
                const location = await userLocation();
                const searchParams = new URLSearchParams(to.search);
                searchParams.append('origin', encodeLatLng(location));
                const paramsObj = {};
                for (const [key, value] of searchParams.entries()) {
                  paramsObj[key] = value;
                }
                history.push(
                  createResourceLocatorString(name, routeConfiguration, params, paramsObj, to.hash)
                );
              }}
              className={css.tooltipLink}
            >
              <span className={css.priorityLinkLabel}>{text}</span>
            </button>
          </a>
        ) : (
          <NamedLink name={name} params={params} to={to} className={classes}>
            <span className={css.priorityLinkLabel}>{text}</span>
          </NamedLink>
        )}
      </>
    );
  }
  return (
    <ExternalLink href={href} className={classes}>
      <span className={css.priorityLinkLabel}>{text}</span>
    </ExternalLink>
  );
};

/**
 * Create priority links, which are visible on the desktop layout on the Topbar.
 * If space is limited, this doesn't include anything to the Topbar.
 *
 * @param {*} props contains links array and setLinks function
 * @returns container div with priority links included.
 */
const PriorityLinks = props => {
  const containerRef = useRef(null);

  // With this useEffect, we measure the widths of each rendered priority link
  // This is done once before the real rendering and it's done outside the viewport.
  useEffect(() => {
    const isMeasured = props.links?.[0]?.width;
    if (containerRef.current && !isMeasured) {
      const linksFromRenderedWrapper = [...containerRef.current.childNodes];
      let cumulatedWidth = 0;
      // Generate an array of link configs with width & cumulatedWidth included
      const linksWithWidths = props.links.reduce((links, l, i) => {
        const width = linksFromRenderedWrapper[i].offsetWidth;
        cumulatedWidth = cumulatedWidth + width;
        return [...links, { ...l, width, cumulatedWidth }];
      }, []);
      props.setLinks(linksWithWidths);
    }
  }, [containerRef]);

  const { links, priorityLinks } = props;
  const isServer = typeof window === 'undefined';
  const isMeasured = links?.[0]?.width && (priorityLinks.length === 0 || priorityLinks?.[0]?.width);
  const styleWrapper = !!isMeasured
    ? {}
    : {
        style: {
          position: 'absolute',
          top: '-2000px',
          left: '-2000px',
          width: '100%',
          height: 'var(--topbarHeightDesktop)',
          display: 'flex',
          flexDirection: 'row',
        },
      };
  const linkConfigs = isMeasured ? priorityLinks : links;

  return isMeasured || isServer ? (
    <div className={css.priorityLinkWrapper} {...styleWrapper} ref={containerRef}>
      {linkConfigs.map(linkConfig => {
        return (
          <PriorityLink
            key={linkConfig.text}
            linkConfig={linkConfig}
            askUserForLocation={props.currentUser === null && props.currentPage === 'LandingPage'}
          />
        );
      })}
    </div>
  ) : (
    ReactDOM.createPortal(
      <div className={css.priorityLinkWrapper} {...styleWrapper} ref={containerRef}>
        {linkConfigs.map(linkConfig => {
          return (
            <PriorityLink
              key={linkConfig.text}
              linkConfig={linkConfig}
              askUserForLocation={props.currentUser === null && props.currentPage === 'LandingPage'}
            />
          );
        })}
      </div>,
      document.body
    )
  );
};

export default PriorityLinks;
